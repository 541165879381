// Core
import React from 'react';
import Cookies from '@components/Cookies';

// Import Swiper Styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import 'swiper/css/bundle';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';

// Libraries
import { QueryClient, QueryClientProvider } from 'react-query';
import { ProvidersWrapper } from '@components/AppContext';
import { I18nextProvider } from 'gatsby-plugin-react-i18next';
import i18next from 'i18next';

const queryClient = new QueryClient();

function wrapElement({ element, props }) {
  // Certifique-se de que o i18n está disponível para todos os componentes
  const { i18n } = props || {};

  return (
    <QueryClientProvider client={queryClient}>
      {i18n ? (
        <I18nextProvider i18n={i18next}>
          <ProvidersWrapper>
            <Cookies />
            {element}
          </ProvidersWrapper>
        </I18nextProvider>
      ) : (
        <ProvidersWrapper>
          <Cookies />
          {element}
        </ProvidersWrapper>
      )}
    </QueryClientProvider>
  );
}
export default wrapElement;
